import React from "react";
import pdfIcon from '../../../assets/icons/icon_pdf.svg';
import contactIcon from '../../../assets/icons/icon_contact.svg';
import { withRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class SideBarListView extends React.Component {
  constructor() {
    super();
    this.linkElement = {
      '/user-guide': React.createRef(),
      '/user-guide/auth': React.createRef(),
      '/user-guide/navigation': React.createRef(),
      '/user-guide/upload': React.createRef(),
      '/user-guide/assets-about': React.createRef(),
      '/user-guide/my-bag': React.createRef(),
      '/user-guide/download': React.createRef()
    };

    this.subLinkElement = {
      '/user-guide/login': React.createRef(),
      '/user-guide/reset-password': React.createRef(),
      '/user-guide/logout': React.createRef(),

      '/user-guide/upload-step1': React.createRef(),
      '/user-guide/upload-step2': React.createRef(),
      '/user-guide/upload-step3': React.createRef(),
      '/user-guide/upload-step4': React.createRef(),
      '/user-guide/upload-step5': React.createRef(),


      '/user-guide/assets-details': React.createRef(),
      '/user-guide/assets-gallery': React.createRef(),
      '/user-guide/assets-delete': React.createRef(),

      '/user-guide/my-bag-create': React.createRef(),
      '/user-guide/my-bag-edit': React.createRef(),
      '/user-guide/my-bag-delete': React.createRef(),

      '/user-guide/download-step1': React.createRef(),
      '/user-guide/download-step2': React.createRef(),
    }

  }

  onNavigate = (link) => {
    this.props.history && this.props.history.push(link);
  }

  toggleLinkItem(key) {
    this.clearActiveClass()
    this.clearSubLinkActiveClass()
    const element = this.linkElement[key].current;
    element.classList.add("active");
  }

  clearActiveClass() {
    for (const [key, value] of Object.entries(this.linkElement)) {
      const element = value.current
      element.classList.remove("active");
    }
  }

  toggleSubLinkItem(key) {
    this.clearSubLinkActiveClass()
    const element = this.subLinkElement[key].current;
    element.classList.add("active");
  }

  clearSubLinkActiveClass() {
    for (const [key, value] of Object.entries(this.subLinkElement)) {
      const element = value.current
      element.classList.remove("active");
    }
  }

  render() {
    const { sideBarView, showBugForm, t, i18n } = this.props;
    const langJp = (i18n && i18n.language && (i18n.language === 'jp' || i18n.language === 'ja-JP'));
    const manualLink = langJp ? 'https://asset.japan.travel/image/upload/v1711691681/dam-admin/pdf/manual_jnto-dam2025_JP.pdf' : 'https://asset.japan.travel/image/upload/v1711691687/dam-admin/pdf/Manual_jnto-dam2025-EN.pdf';


    return (
      <>
        <div className='sidebar-main-title sidebar-wrapper'>{t('header.userGuide')}</div>
        <div className='list-view'>
          <ol className="ordered-list">
            <li ref={this.linkElement['/user-guide']} className='no-arrow'><div><a onClick={() => {this.onNavigate('/user-guide'); this.toggleLinkItem('/user-guide')}}>{t('manual.welcomePage.menuHeader')}</a></div></li>

            <li ref={this.linkElement['/user-guide/auth']}><div><a onClick={() => {this.onNavigate('/user-guide/auth'); this.toggleLinkItem('/user-guide/auth')}}>{t('manual.userAuth.menuHeader')}</a></div>
              <ol className="ordered-sub-list">
                <li ref={this.subLinkElement['/user-guide/login']}><div><a onClick={() => {this.onNavigate('/user-guide/login'); this.toggleSubLinkItem('/user-guide/login')}}>{t('manual.login.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/reset-password']}><div><a onClick={() => {this.onNavigate('/user-guide/reset-password'); this.toggleSubLinkItem('/user-guide/reset-password')}}>{t('manual.resetPassword.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/logout']}><div><a onClick={() => {this.onNavigate('/user-guide/logout');this.toggleSubLinkItem('/user-guide/logout')}}>{t('manual.logout.title')}</a></div></li>
              </ol>
            </li>

            <li ref={this.linkElement['/user-guide/navigation']} className='no-arrow'><div><a onClick={() => {this.onNavigate('/user-guide/navigation'); this.toggleLinkItem('/user-guide/navigation')}}>{t('manual.navigation.menuHeader')}</a></div></li>

            <li ref={this.linkElement['/user-guide/upload']}>
              <div><p className="sub-heading">
                <a onClick={() => {this.onNavigate('/user-guide/upload'); this.toggleLinkItem('/user-guide/upload')}}>{t('manual.upload.menuHeader')}</a></p></div>
              <ol className="ordered-sub-list">
                <li ref={this.subLinkElement['/user-guide/upload-step1']}><div><a onClick={() => {this.onNavigate('/user-guide/upload-step1'); this.toggleSubLinkItem('/user-guide/upload-step1')}}>{t('manual.upload.step1.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/upload-step2']}><div><a onClick={() => {this.onNavigate('/user-guide/upload-step2'); this.toggleSubLinkItem('/user-guide/upload-step2')}}>{t('manual.upload.step2.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/upload-step3']}><div><a onClick={() => {this.onNavigate('/user-guide/upload-step3'); this.toggleSubLinkItem('/user-guide/upload-step3')}}>{t('manual.upload.step3.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/upload-step4']}><div><a onClick={() => {this.onNavigate('/user-guide/upload-step4'); this.toggleSubLinkItem('/user-guide/upload-step4')}}>{t('manual.upload.step4.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/upload-step5']}><div><a onClick={() => {this.onNavigate('/user-guide/upload-step5'); this.toggleSubLinkItem('/user-guide/upload-step5')}}>{t('manual.upload.step5.title')}</a></div></li>
              </ol>
            </li>

            <li ref={this.linkElement['/user-guide/assets-about']}><div><a onClick={() => {this.onNavigate('/user-guide/assets-about');this.toggleLinkItem('/user-guide/assets-about')}}>{t('manual.assets.menuHeader')}</a></div>
              <ol className="ordered-sub-list">
                <li ref={this.subLinkElement['/user-guide/assets-details']}><div><a onClick={() => {this.onNavigate('/user-guide/assets-details'); this.toggleSubLinkItem('/user-guide/assets-details')}}>{t('manual.assets.assetsDetails.menuHeader')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/assets-gallery']}><div><a onClick={() => {this.onNavigate('/user-guide/assets-gallery'); this.toggleSubLinkItem('/user-guide/assets-gallery')}}>{t('manual.assets.assetsGallery.menuHeader')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/assets-delete']}><div><a onClick={() => {this.onNavigate('/user-guide/assets-delete'); this.toggleSubLinkItem('/user-guide/assets-delete')}}>{t('manual.assets.assetsDelete.menuHeader')}</a></div></li>
              </ol></li>

            <li ref={this.linkElement['/user-guide/my-bag']}><div style={{ color: 'grey' }}><a onClick={() => {this.onNavigate('/user-guide/my-bag');this.toggleLinkItem('/user-guide/my-bag')}}>{t('manual.myBag.menuHeader')}</a></div>
              <ol className="ordered-sub-list">
                <li ref={this.subLinkElement['/user-guide/my-bag-create']}><div><a onClick={() => {this.onNavigate('/user-guide/my-bag-create'); this.toggleSubLinkItem('/user-guide/my-bag-create')}}>{t('manual.myBag.create.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/my-bag-edit']}><div><a onClick={() => {this.onNavigate('/user-guide/my-bag-edit'); this.toggleSubLinkItem('/user-guide/my-bag-edit')}}>{t('manual.myBag.edit.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/my-bag-delete']}><div><a onClick={() => {this.onNavigate('/user-guide/my-bag-delete'); this.toggleSubLinkItem('/user-guide/my-bag-delete')}}>{t('manual.myBag.delete.title')}</a></div></li>
              </ol></li>

            <li ref={this.linkElement['/user-guide/download']}><div style={{ color: 'grey' }}><a onClick={() => {this.onNavigate('/user-guide/download');this.toggleLinkItem('/user-guide/download')}}>{t('manual.download.menuHeader')}</a></div>
              <ol className="ordered-sub-list">
                <li ref={this.subLinkElement['/user-guide/download-step1']}><div><a onClick={() => {this.onNavigate('/user-guide/download-step1'); this.toggleSubLinkItem('/user-guide/download-step1')}}>{t('manual.download.step1.title')}</a></div></li>
                <li ref={this.subLinkElement['/user-guide/download-step2']}><div><a onClick={() => {this.onNavigate('/user-guide/download-step2'); this.toggleSubLinkItem('/user-guide/download-step2')}}>{t('manual.download.step2.title')}</a></div></li>
              </ol></li>
            {/* <li><div style={{ color: 'grey' }}><a onClick={() => this.onNavigate('/user-guide')}>{t('manual.admin.title')}</a></div></li> */}
          </ol>
        </div>
        
        <div className='bottom-content sidebar-wrapper'>
              <a onClick={showBugForm}>
                <div className='box-item'>
                  <div className='box-item-icon'>
                    <img className='icon' src={contactIcon} />
                  </div>
                  <div className='box-item-content'>
                    {t('sideBar.contact')}
                  </div>
                </div>
              </a>

              <a href={manualLink} target="_blank" rel="noopener noreferrer">
                <div className='box-item'>
                  <div className='box-item-icon'>
                    <img className='icon' src={pdfIcon} />
                  </div>
                  <div className='box-item-content'>
                    {t('sideBar.pdf')}
                  </div>
                </div>
              </a>
            </div>
      </>
    )
  }
}

export default withTranslation()(withRouter(SideBarListView));

